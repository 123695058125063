import(/* webpackMode: "eager" */ "/Users/yiminghan/lavareach/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/yiminghan/lavareach/node_modules/react-wrap-balancer/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yiminghan/lavareach/src/components/landing/clients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedBeamCRM"] */ "/Users/yiminghan/lavareach/src/components/landing/integrations-beam.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yiminghan/lavareach/src/components/landing/number-ticker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TryLavaReachButton"] */ "/Users/yiminghan/lavareach/src/components/landing/try-lavareach-button/try-lavareach-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DemoVideoPopUp"] */ "/Users/yiminghan/lavareach/src/components/landing/video-popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/Users/yiminghan/lavareach/src/components/ui/label.tsx");
