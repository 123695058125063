"use client";

import React, { useState } from "react";
import { CompactDialogContent, Dialog } from "../ui/dialog";
import { AwesomeButton } from "react-awesome-button";

export function DemoVideoPopUp() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <AwesomeButton type="secondary" onMouseDown={() => setOpen(true)}>
        Demo Video
      </AwesomeButton>
      <div className="z-[100]">
        <Dialog open={isOpen} onOpenChange={setOpen}>
          <CompactDialogContent className="max-w-[909px] bg-transparent border-none shadow-none">
            <iframe
              src="https://www.youtube.com/embed/tzCbev_KpMI?si=4FiB-jh8P67y_Pfh?autoplay=1"
              width="100%"
              height="480"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture;"
              allowFullScreen
            />
          </CompactDialogContent>
        </Dialog>
      </div>
    </>
  );
}
